<app-logos class="full-frame-fixed"></app-logos>

<div class="white-block-outer">
    <div class="white-block">
        <h3>Verlengingsverzoek</h3>
        <div class="content" *ngIf="order != null && !error">
            <div class="element">

                <p *ngIf="!done">
                    Brick Configurator: {{order.ProjectNumber.toString()| padleft:4}} - {{order.Project.Plaats}} -
                    {{order.Project.Projectnaam}}
                </p>

                <div class="buttons" *ngIf="!done">
                    <a class="button red rounded" [ngClass]="{'disabled' : busy}" (click)="send(true)">TOESTAAN</a>
                    <a class="button red rounded" [ngClass]="{'disabled' : busy}" (click)="send(false)">WEIGEREN</a>
                </div>

                <p class="note">
                    Er wordt een e-mail verstuurd naar het opgegeven klant e-mailadres van de tovertafel en een kopie
                    mail naar BrickPoint.
                </p>

            </div>

        </div>
        <div class="content" *ngIf="error">
            <div class="element">
                <p class="note">
                    Error! Code <span style="font-weight:bold">{{code}}</span> is niet gevonden!
                </p>
            </div>
        </div>
    </div>
</div>