//angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

//external modules
import { UniversalCookieConsentModule } from 'universal-cookie-consent';

//app bootstrap
import { AppComponent } from './app.component';

//services
import { LibraryService } from './services/library.service';
import { CodeService } from './services/code.service';
import { DialogService } from './shared/dialog/dialog.service';
import { HintService } from './shared/hint/hint.service';

//directives
import { DownloadDirective } from './directives/download.directive';
import { FadeVisibleDirective } from './directives/fade-visible.directive';

//pipes
import { PadLeftPipe } from './pipes/padleft.pipe';

//shared/sub components
import { HintComponent } from './shared/hint/hint.component';
import { LogosComponent } from './sub-components/logos/logos.component';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { PillComponent } from './pages/config/browse/pill/pill.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { DialogComponent } from './shared/dialog/dialog.component';

//the most important pages
import { ConfigbaseComponent } from './pages/config/configbase.component';
import { HomeComponent } from './pages/home/home.component';
import { ConfigComponent } from './pages/config/config/config.component';
import { OverviewComponent } from './pages/config/overview/overview.component';
import { BrowseComponent } from './pages/config/browse/browse.component';

//non login components
import { DownloadComponent } from './pages/download/download.component';
import { ExtensionComponent } from './pages/extension/extension.component';
import { MediaQueryService } from './services/media-query.service';
import { BrowseRoofComponent } from './pages/config/browse-roof/browse-roof.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConfigComponent,
    OverviewComponent,
    DownloadComponent,
    BrowseComponent,
    ExtensionComponent,
    FadeVisibleDirective,
    LogosComponent,
    DownloadDirective,
    PadLeftPipe,
    HintComponent,
    ConfigbaseComponent,
    DropdownComponent,
    PillComponent,
    SidebarComponent,
    DialogComponent,
    BrowseRoofComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    UniversalCookieConsentModule.forRoot({
      autoShow: true,

      customizeText: 'Aanpassen',
      acceptText: 'Doorgaan',
      introText:
        'Deze website maakt gebruik van cookies. Door op ‘akkoord’ te klikken ga je akkoord met de plaatsing hiervan. <a href="https://www.brickpoint.nl/privacy-verklaring" target="_blank">Klik hier</a> voor onze privacy verklaring.',
      customizeIntroText: 'Geef uw cookie-voorkeuren aan:',
      backText: 'Terug',

      saveText: 'Doorgaan',
      consentTypes: [
        {
          id: 'base',
          title: 'Basis functionaliteit',
          description:
            'Deze cookies worden gebruikt om uw gemaakte wijzigingen \nte bewaren voordat deze verstuurd worden\nen kunnen dus niet uitgeschakeld worden.',
          mandatory: true,
        },
        // {
        //   id: 'analytics',
        //   title: "Analytics",
        //   description: "Wordt gebruikt om bij te houden hoe vaak deze website wordt gebruikt - hierbij worden geen persoonlijke gegevens gebruikt!",
        //   mandatory: false
        // }
      ],
      disableBodyScroll: true,
    }),
  ],
  providers: [
    LibraryService,
    CodeService,
    PadLeftPipe,
    DialogService,
    HintService,
    MediaQueryService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
