import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public contentChanged = new EventEmitter();

  public message: string = '';

  public ok: string = '';
  public cancel: string = '';

  public lastResult: boolean = false;

  constructor() {}

  public show(message: string, ok: string, cancel: string = '') {
    this.message = message;
    this.ok = ok;
    this.cancel = cancel;

    this.isVisible.next(true);
  }

  public updateMessage(message: string) {
    this.message = message;
    this.contentChanged.emit();
  }

  public updateOk(ok: string) {
    this.ok = ok;
    this.contentChanged.emit();
  }

  public updateCancel(cancel: string) {
    this.cancel = cancel;
    this.contentChanged.emit();
  }

  public hide(result: boolean) {
    this.lastResult = result;

    this.message = '';
    this.ok = '';
    this.cancel = '';

    this.isVisible.next(false);
  }
}
