<app-logos class="full-frame-fixed"></app-logos>

<div class="white-block-outer">
    <div class="white-block" [ngClass]="{'list': selectedElement.length == 0}">

        <ng-container *ngIf="selectedElement.length == 0">
            <h3>Download hier uw texturen</h3>
            <div class="content" *ngIf="exportData != null">
                <div class="element"
                    *ngFor="let element of exportData; let index=index; let first = first; let last=last"
                    [ngClass]="{'accent':element.Accent != null, 'first': first, 'last': last}">
                    <div class="number">{{index + 1}}</div>
                    <div class="details">
                        <span>Gevelsteen:</span>
                        <span [innerHTML]="sanitize(element.Basis.Label)"></span>

                        <span>Voegkleur:</span>
                        <span>{{element.Basis.Voegkleur}}</span>

                        <span>Metselverband:</span>
                        <span>{{element.Basis.Legverband}}</span>

                        <ng-container *ngIf="element.Accent != null">
                            <span class="spacing">Accentsteen:</span>
                            <span class="spacing" [innerHTML]="sanitize(element.Accent.Label)"></span>

                            <span>Voegkleur:</span>
                            <span>{{element.Accent.Voegkleur}}</span>

                            <span>Metselverband:</span>
                            <span>{{element.Accent.Legverband}}</span>
                        </ng-container>
                    </div>

                    <div class="download-links">
                        <a class="button red download rounded small-ish"
                            (click)="clickedTexture(element.Basis)">Download</a>
                        <a class="button red download rounded small-ish" *ngIf="element.Accent != null"
                            (click)="clickedTexture(element.Accent)">Download</a>

                    </div>
                </div>

            </div>
        </ng-container>

        <ng-container *ngIf="selectedElement.length > 0">
            <a class="back-button button rounded" (click)="clickedBack()">
                <img src="./assets/ui/chevron-left.svg">
                Terug
            </a>

            <div class="download-content">
                <div class="preview" *ngFor="let el of selectedElement">
                    <img [src]="el.URL" />
                    <a class="button red rounded small-ish" [href]="el.URL" target="_blank"
                        [download]="el.Filename">Download</a>
                </div>
            </div>
        </ng-container>
    </div>
</div>