import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Order } from '../models/order';
import { Postdata } from '../models/postdata';
import { ReturnData } from '../models/returndata';
import { CodeService as CodeService } from './code.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private order: Order | undefined = undefined;
  static readonly KEY_CODE: string = "brickpoint-order";

  public gotOrder: BehaviorSubject<Order | undefined> = new BehaviorSubject<Order | undefined>(undefined);

  constructor(private http: HttpClient, private codeService: CodeService) { }

  public init() {

    const code = this.codeService.getCode();

    if (code == null) {
      console.log("ERROR, code is null");
      return;
    }

    if (this.isSaved()) {
      console.log("LOAD CACHED ORDER");
      this.load();
      this.gotOrder.next(this.order);
    } else {

      let postdata = new Postdata<string>();
      postdata.data = code;

      const form = new FormData();
      form.append("data", JSON.stringify(postdata));

      console.log("DOWNLOADING ORDER");
      this.http.post<ReturnData<Order>>("./api/get-order-from-code/", form).subscribe((result) => {
        this.order = result.data;
        this.save();
        this.gotOrder.next(this.order);
      });
    }
  }

  private isSaved() {

    const storageItem = sessionStorage.getItem(OrderService.KEY_CODE);
    if (storageItem != null && storageItem != "") {
      //check if code matches
      const order = <Order>JSON.parse(storageItem);
      return (order.OnlineCode == this.codeService.getCode());
    }
    return false;
  }

  private load() {
    const storageItem = sessionStorage.getItem(OrderService.KEY_CODE);
    if (storageItem != null && storageItem != "") {
      this.order = <Order>JSON.parse(storageItem);
    }

  }

  public save() {
    console.log("saving latest version of order");
    console.log(this.order);
    sessionStorage.setItem(OrderService.KEY_CODE, JSON.stringify(this.order));

  }

  public resetSave() {
    sessionStorage.removeItem(OrderService.KEY_CODE);
  }
}
