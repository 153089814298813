import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { HintService } from './hint.service';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit, OnDestroy {

  readonly HINT_WIDTH: number = 275;
  readonly HINT_MARGIN: number = 5;
  readonly SCROLL_MARGIN: number = 10;

  visible: boolean = false;
  message: string = "";

  @ViewChild("hint", { static: true }) hint?: ElementRef = undefined;

  routerSub?: Subscription = undefined;
  hintSub?: Subscription = undefined;

  constructor(private hintService: HintService, private router: Router) {
    this.onResize();
    this.routerSub = this.router.events.subscribe((val) => { this.onRouterEvent(val); });
  }

  onRouterEvent(val: Event) {
    if (val instanceof NavigationEnd && this.visible) {
      this.hintService.hide();
    }
  }

  ngOnInit(): void {
    this.hintSub = this.hintService.isVisible.subscribe((state) => {
      this.hintStateChanged(state);
    });
  }

  ngOnDestroy() {
    if (this.hintSub != undefined)
      this.hintSub.unsubscribe();
    if (this.routerSub != undefined)
      this.routerSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.position();
  }

  hintStateChanged(state: boolean) {
    this.visible = state;

    if (state) {
      this.message = this.hintService.message;

      this.position();
    }
  }

  position() {
    if (this.hint == undefined)
      return;

    const hint = <HTMLElement>this.hint.nativeElement;

    let x: number = 0;
    let y: number = 0;
    if (this.hintService.ref != null) {
      const el = <HTMLElement>this.hintService.ref.nativeElement;
      const rect = el.getBoundingClientRect();

      x = rect.left;
      y = rect.top;

      if (this.hintService.offset != undefined) {
        x += this.hintService.offset.x;
        y += this.hintService.offset.y;
      }
    } else {
      //half screen
      x = 0.5 * (window.innerWidth - this.HINT_WIDTH);
      y = 0.5 * window.innerHeight - 100;
    }

    if (x < 0) {
      x = this.HINT_MARGIN;
    }

    if (y < 0) {
      y = this.HINT_MARGIN;
    }

    //for right: keep scrollrect margin
    if (x + this.HINT_WIDTH >= window.innerWidth)
      x = window.innerWidth - this.HINT_WIDTH - this.HINT_MARGIN - this.SCROLL_MARGIN;

    if (hint != undefined) {

      hint.style.left = x + "px";
      hint.style.top = y + "px";
    }

  }

  close() {
    this.hintService.hide();
  }

}

