import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

export class DropdownData {
  public label: string = "";
  public internalvalue: any;
  //only used when multi-select is true!!
  public checked: boolean = false;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input("multi-select") multiSelect: boolean = false;
  @Output("multiValueChanged") multiValueChanged = new EventEmitter();

  @Input("dropdown-data") dropdownData: DropdownData[] = [];
  @Input("placeholder") placeholder: string = "";

  //only used when multi select is false!!
  @Input("value") value?: any = undefined;
  @Output("valueChange") valueChange = new EventEmitter<any>();

  @Input("checkedSrc") checkedSrc: string = "";
  @Input("uncheckedSrc") uncheckedSrc: string = "";
  @Input("arrowSrc") arrowSrc: string = "";

  @Input("footer") footer: TemplateRef<any> | null = null;

  open: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickedDropdown() {
    this.open = true;
  }

  clickedBlocker() {
    this.open = false;
  }

  getLabel(): string {
    if (!this.multiSelect) {
      if (this.value != undefined) {
        //find value
        const sel = this.dropdownData.find(x => x.internalvalue == this.value);
        if (sel != undefined)
          return sel.label;
      }
    }

    return this.placeholder;
  }

  clickedValue(val: DropdownData) {

    if (this.multiSelect) {
      val.checked = !val.checked;
      this.multiValueChanged.emit();
    } else {
      this.value = val.internalvalue;
      this.valueChange.emit(this.value);
    }
    this.open = false;
  }

}
