import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ɵNG_ELEMENT_ID } from '@angular/core';

@Directive({
  selector: '[appFadeVisible]'
})
export class FadeVisibleDirective implements OnChanges {

  @Input("appFadeVisible") visible: boolean = false;

  _el?: HTMLElement = undefined;

  constructor(private el: ElementRef) {
    this._el = <HTMLElement>this.el.nativeElement;
  }

  add(s: string): void {
    if (!this._el?.classList.contains(s))
      this._el?.classList.add(s);
  }

  remove(s: string): void {
    if (this._el?.classList.contains(s))
      this._el?.classList.remove(s);
  }

  has(s: string): boolean {
    if (this._el == undefined)
      return false;

    return this._el?.classList.contains(s);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.visible === true) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {

    if (this.has("hidden")) {
      this.remove("hidden");
      this.add("fade-in");

      //recheck because of timeout. It might have been changed (double click)
      setTimeout(() => {
        if (this.visible)
          this.remove("fade-in");
      }, 225);
    }
  }

  hide() {

    if (!this.has("hidden")) {
      this.add("fade-out");
      //recheck because of timeout. It might have been changed (double click)
      setTimeout(() => {
        if (!this.visible) {
          this.add("hidden");
          this.remove("fade-out");
        }
      }, 225);
    }
  }


}
