<app-logos [right]="false"></app-logos>
<app-sidebar *ngIf="order != undefined && library != undefined" class="full-frame" #sidebar>

    <div slot="frame" class="frame-content">


        <div class="texture" #textureRoot>
            <div class="loading" *ngIf="generating">
                <svg fill="none" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10"
                        filter="url(#shadow)">
                    </circle>
                    <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10">
                    </circle>
                </svg>
            </div>
            <div class="texture-view" [ngStyle]="textureContainerStyle" #textureContainer>

                <div class="basis" [ngStyle]="basisContainerStyle"></div>
                <div class="accent" [ngStyle]="accentContainerStyle"></div>
                <div class="roof" [ngStyle]="roofContainerStyle"></div>

            </div>

        </div>


        <div class="bottom-row-buttons-frame">
            <a #generateButton class="button sea rounded small-ish" (click)="clickedGenerate()"
                *ngIf="!orderElement.IsDakpan">
                Genereer textuur
            </a>
            <a class="button sea-outline rounded small-ish" (click)="clickedReset()">
                Reset
            </a>
        </div>

    </div>




    <div slot="sidebar" class="sidebar-content">
        <div class="scroll-parent-flex desktop-only main-scroll">

            <div class="scroll-container desktop-only">

                <div class="tab-bar">
                    <a class="tab" [ngClass]="{'active': tab == 'configure'}" (click)="tab='configure'">Configureer</a>
                    <a #collectionButton class="tab" (click)="clickedCollection(orderElement.SteenElementBasis)"
                        *ngIf="!orderElement.IsDakpan">Onze artikelen</a>
                    <a #collectionButton class="tab" (click)="clickedCollection(orderElement.SteenElementBasis)"
                        *ngIf="orderElement.IsDakpan">Onze artikelen</a>
                    <a class="tab" [ngClass]="{'active': tab == 'overzicht'}" (click)="tab='overzicht'">Mijn
                        overzicht</a>
                </div>

                <div class="options" *ngIf="tab == 'configure'">


                    <div class="hor-block">
                        <div class="block">
                            <h4>Selecteer uw opties</h4>
                            <!-- gevelsteen or dakpan -->
                            <div class="radio row">
                                <a class="radio-button" [ngClass]="{'selected' : !orderElement.IsDakpan}"
                                    (click)="orderElement.IsDakpan = false">
                                    <img src="./assets/ui/icon-checkbox-unchecked.svg" *ngIf="orderElement.IsDakpan">
                                    <img src="./assets/ui/icon-checkbox-checked.svg" *ngIf="!orderElement.IsDakpan">
                                    Gevelsteen
                                </a>
                                <a class="radio-button" [ngClass]="{'selected' : orderElement.IsDakpan}"
                                    (click)="orderElement.IsDakpan = true">
                                    <img src="./assets/ui/icon-checkbox-unchecked.svg" *ngIf="!orderElement.IsDakpan">
                                    <img src="./assets/ui/icon-checkbox-checked.svg" *ngIf="orderElement.IsDakpan">
                                    Dakpan
                                </a>
                            </div>
                        </div>

                        <div class="block" *ngIf="!orderElement.IsDakpan">
                            <!-- halfsteens or wild -->
                            <h4>Patroon</h4>
                            <div class="radio row">
                                <a class="radio-button"
                                    [ngClass]="{'selected' : orderElement.Verband == Verband.Halfsteens}"
                                    (click)="orderElement.Verband = Verband.Halfsteens">
                                    <img src="./assets/ui/icon-checkbox-unchecked.svg"
                                        *ngIf="orderElement.Verband != Verband.Halfsteens">
                                    <img src="./assets/ui/icon-checkbox-checked.svg"
                                        *ngIf="orderElement.Verband == Verband.Halfsteens">
                                    Halfsteens
                                </a>
                                <a class="radio-button" [ngClass]="{'selected' : orderElement.Verband == Verband.Wild}"
                                    (click)="orderElement.Verband = Verband.Wild">
                                    <img src="./assets/ui/icon-checkbox-unchecked.svg"
                                        *ngIf="orderElement.Verband != Verband.Wild">
                                    <img src="./assets/ui/icon-checkbox-checked.svg"
                                        *ngIf="orderElement.Verband == Verband.Wild">
                                    Wild
                                </a>
                            </div>
                        </div>
                    </div>




                    <!-- gevelsteen specific -->
                    <ng-container *ngIf="!orderElement.IsDakpan">

                        <!-- main brick -->
                        <div class="brick row topm">

                            <div class="brick content">

                                <h4>Gevelsteen code</h4>
                                <div class="row">
                                    <div class="code">
                                        <div class="input">
                                            <input type="text" placeholder="code" class="code"
                                                [ngClass]="{'projectsteen': basisIsProjectsteen }"
                                                [(ngModel)]="orderElement.SteenElementBasis.Code"
                                                (ngModelChange)="basisSteenValChange($event)">
                                            <a #collectionButton class="button red-outline rounded small"
                                                (click)="clickedCollection(orderElement.SteenElementBasis)">Collectie</a>
                                        </div>
                                    </div>

                                    <div class="voegkleur">
                                        <!-- <h4>Voegkleur</h4> -->
                                        <app-dropdown class="configpage" [dropdown-data]="voegKleuren"
                                            placeholder="Voegkleur" [multi-select]="false"
                                            [(value)]="orderElement.SteenElementBasis.Voegkleur"
                                            arrowSrc="./assets/ui/angle-right-regular.png"
                                            uncheckedSrc="./assets/ui/icon-checkbox-unchecked.svg"
                                            checkedSrc="./assets/ui/icon-checkbox-checked.svg">
                                        </app-dropdown>
                                    </div>
                                </div>

                            </div>
                            <input class="omschrijving" type="text" placeholder="omschrijving (optioneel)"
                                [(ngModel)]="orderElement.SteenElementBasis.KlantNotitie">
                        </div>


                        <!-- accent brick -->
                        <div class="brick row ">

                            <div class="brick content">


                                <h4>Accentsteen code</h4>
                                <div class="row">
                                    <div class="code">
                                        <div class="input">
                                            <input type="text" placeholder="code" class="code"
                                                [ngClass]="{'projectsteen': accentIsProjectsteen }"
                                                [(ngModel)]="orderElement.SteenElementAccent.Code"
                                                (ngModelChange)="accentSteenValChange($event)">
                                            <a class="button red-outline rounded small"
                                                (click)="clickedCollection(orderElement.SteenElementAccent)">Collectie</a>
                                        </div>
                                    </div>

                                    <div class="voegkleur">
                                        <!-- <h4>Voegkleur</h4> -->
                                        <app-dropdown class="configpage" [dropdown-data]="voegKleuren"
                                            placeholder="Voegkleur" [multi-select]="false"
                                            [(value)]="orderElement.SteenElementAccent.Voegkleur"
                                            arrowSrc="./assets/ui/angle-right-regular.png"
                                            uncheckedSrc="./assets/ui/icon-checkbox-unchecked.svg"
                                            checkedSrc="./assets/ui/icon-checkbox-checked.svg">
                                        </app-dropdown>
                                    </div>
                                </div>
                            </div>
                            <input class="omschrijving" type="text" placeholder="omschrijving (optioneel)"
                                [(ngModel)]="orderElement.SteenElementAccent.KlantNotitie">
                        </div>

                    </ng-container>
                    <!-- dakpan specific -->
                    <ng-container *ngIf="orderElement.IsDakpan">
                        <!-- dakpan row -->
                        <div class="brick row">
                            <div class="brick content">
                                <div class="code">
                                    <h4>Dakpan</h4>
                                    <div class="input">
                                        <span *ngIf="roofImageString != '' || generating"
                                            [innerHTML]="getRooftileLabel(orderElement.DakElement.Code,false)"></span>
                                        <a #collectionButton class="button red-outline rounded small"
                                            (click)="clickedCollectionRoof()">Collectie</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="brick row">
                            <div class="brick content">
                                <div class="code">
                                    <div class="input roof-desc"
                                        [innerHTML]="getRooftileDescription(orderElement.DakElement.Code)">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </ng-container>


                    <div class="grow"></div>

                    <div class="buttons row bottom">
                        <a #addButton class="button red rounded small-ish"
                            [ngClass]="{'disabled': !textureDisplayReady}" (click)="clickedAdd()">
                            {{ existingElementRef != undefined ? "Wijziging opslaan" : "Toevoegen aan overzicht" }}
                        </a>
                        <a #overviewButton class="button text with-icon small-ish" (click)="clickedOverzicht()">
                            <span>Naar aanvraag</span>
                            <img src="./assets/ui/arrow-right-light-line.svg" />
                        </a>
                    </div>


                </div>

                <div class="overview" *ngIf="tab == 'overzicht'">
                    <!-- <h3>Overzicht keuzes</h3> -->

                    <div class=" scroll-parent-flex">
                        <div class="scroll-container">

                            <div class="element" [ngClass]="{'active': existingElementRef == el}"
                                *ngFor="let el of order.OrderElements; let index = index">

                                <span class="number">{{index+1}}</span>

                                <div class="details pointer" (click)="clickedEditExisting(el)">

                                    <!-- steen element basis -->
                                    <ng-container *ngIf="!el.IsDakpan">
                                        <span>Gevelsteen</span>
                                        <span [innerHTML]="getBrickLabel(el.SteenElementBasis.Code, el.Verband)"></span>
                                        <span>Voegkleur</span>
                                        <span>{{getVoegLabelWithID(el.SteenElementBasis.Voegkleur)}}</span>
                                        <span>Metselverband</span>
                                        <span>{{getVerbandLabel(el.Verband)}}</span>
                                        <span>Omschrijving</span>
                                        <span>{{el.SteenElementBasis.KlantNotitie}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="!el.IsDakpan && el.SteenElementAccent.Code != ''">
                                        <span class="empty"></span><span></span>
                                        <span>Accentsteen</span>
                                        <span
                                            [innerHTML]="getBrickLabel(el.SteenElementAccent.Code, el.Verband)"></span>
                                        <span>Voegkleur</span>
                                        <span>{{getVoegLabelWithID(el.SteenElementAccent.Voegkleur)}}</span>
                                        <span>Metselverband</span>
                                        <span>{{getVerbandLabel(el.Verband)}}</span>
                                        <span>Omschrijving</span>
                                        <span>{{el.SteenElementAccent.KlantNotitie}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="el.IsDakpan">
                                        <span>Dakpan:</span>
                                        <span [innerHTML]="getRooftileLabel(el.DakElement.Code,true)"></span>

                                        <span>Omschrijving:</span>
                                        <span [innerHTML]="getRooftileDescription(el.DakElement.Code)"></span>
                                    </ng-container>


                                </div>

                                <div class="pointer delete" (click)="clickedDeleteExisting(el)">
                                    <img src="./assets/ui/Icon material-delete.svg">
                                </div>

                            </div>


                        </div>
                    </div>
                    <div class="grow"></div>
                    <div class="buttons row bottom">
                        <a #overviewButton class="button red rounded small-ish" (click)="tab = 'configure'">
                            Terug naar configurator
                        </a>
                        <a #overviewButton2 class="button text with-icon small-ish" (click)="clickedOverzicht()">
                            <span>Naar aanvraag</span>
                            <img src="./assets/ui/arrow-right-light-line.svg" />
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</app-sidebar>

<app-browse [appFadeVisible]="browsing" [enabled]="browsing" class="hidden" [verband]="browseVerband"
    [voegkleur]="browseVoegkleur" (onBrowseDone)="onBrowseDone($event)"></app-browse>

<app-browse-roof [appFadeVisible]="browsingRoof" [enabled]="browsingRoof" class="hidden"
    (onBrowseDone)="onBrowseRoofDone($event)" [startupfilterModel]="browseRoofModel"
    [startupfilterType]="browseRoofType"></app-browse-roof>