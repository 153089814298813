import { Component, DebugElement, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Display, MediaQueryService } from 'src/app/services/media-query.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy {

  @ViewChild("bar", { static: false }) bar?: ElementRef = undefined;

  canToggle: boolean = false;
  isOpen: boolean = true;

  mediaSub: Subscription;

  animating: boolean = false;

  cachedDisplay: Display = Display.Undefined;

  constructor(private mediaQuery: MediaQueryService) {
    this.mediaSub = mediaQuery.changed.subscribe((media) => { this.mediaChanged(media); });
  }

  ngOnInit(): void {
    this.mediaChanged(this.mediaQuery.getMedia());
  }

  ngOnDestroy() {
    if (this.mediaSub != undefined)
      this.mediaSub.unsubscribe();
  }


  mediaChanged(media: Display) {

    this.cachedDisplay = media;

    if (media >= Display.Medium) {

      if (!this.isOpen) {
        this.open();
      }
      this.canToggle = false;

    } else {
      this.canToggle = true;
    }
  }

  public toggle() {

    if (!this.canToggle)
      return;

    if (this.isOpen) {
      this.close();
    }
    else {
      this.open();
    }
  }


  public open() {
    if (!this.canToggle || this.isOpen) {
      return;
    }

    this.isOpen = true;

    this.animating = true;

    setTimeout(() => {
      this.animating = false;

      if (this.bar != undefined) {
        const _bar = <HTMLElement>this.bar.nativeElement;
        _bar.style.height = "auto";
      }
    }, 250);
  }

  public close() {
    if (!this.canToggle || !this.isOpen) {
      return;
    }

    if (this.bar != undefined) {
      const _bar = <HTMLElement>this.bar.nativeElement;
      _bar.style.height = _bar.scrollHeight + "px";
    }
    this.animating = true;

    setTimeout(() => {
      this.isOpen = false;
      setTimeout(() => { this.animating = false; }, 250);
    }, 50);


  }

}
