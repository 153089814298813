<div class="hint-outer hidden" [appFadeVisible]="visible">

    <div class="hint-inner" #hint>
        <a class="icon-button-close hover" (click)="close()"><img src="./assets/ui/icon-close-red.svg"></a>

        <p class="hint-message">{{message}}</p>

        <a class="ok button red small rounded" (click)="close()">BEGREPEN</a>


    </div>

</div>