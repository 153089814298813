<div class="frame-with-sidebar"
    [ngClass]="{'open': ((canToggle && isOpen) || !canToggle ), 'closed': (canToggle && !isOpen), 'animating': animating}">
    <div class="frame">
        <ng-content select="[slot=frame]"></ng-content>
    </div>

    <!-- *ngStyle="getStyle()" -->
    <div class="sidebar" #bar>
        <div class="sidebar-gradient"></div>
        <div class="sidebar-corner"></div>
        <!-- <div class="bg"><img src="./assets/ui/swirl.svg"></div> -->
        <ng-content select="[slot=sidebar]"></ng-content>

        <div class="toggle-button" *ngIf="canToggle" (click)="toggle()">
            <img src="./assets/ui/icon-arrow-down.svg" />
        </div>
    </div>
</div>