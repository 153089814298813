import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeHtml,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Helpers } from 'src/app/models/helpers';
import { BrickLibrary } from 'src/app/models/library';
import { Order, OrderElement, Verband, Voegkleur } from 'src/app/models/order';
import { Postdata } from 'src/app/models/postdata';
import { LibraryService } from 'src/app/services/library.service';
import { OrderService } from 'src/app/services/order.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  order?: Order = undefined;
  library?: BrickLibrary = undefined;

  getGenderString = Helpers.getGenderString;

  dialogTimeout: number = 6;
  busy: boolean = false;

  dialogSub?: Subscription = undefined;
  libSub?: Subscription = undefined;
  orderSub?: Subscription = undefined;

  images: Promise<SafeResourceUrl>[] = [];

  constructor(
    private router: Router,
    private orderService: OrderService,
    private libService: LibraryService,
    private dialogService: DialogService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.dialogSub = this.dialogService.isVisible.subscribe((state) => {
      this.dialogStateChanged(state);
    });

    this.busy = true;
  }
  ngAfterViewInit(): void {
    this.libSub = this.libService.gotLibrary.subscribe((library) => {
      this.library = library;

      this.orderSub = this.orderService.gotOrder.subscribe((order) => {
        if (order != undefined) {
          //before setting the order and therefore starting the ngFor loop, pre-fill image array
          //and prepare the downloads
          this.prepareThumbnails(order);

          this.order = order;
          this.busy = false;
        }
      });
    });
  }

  prepareThumbnails(order: Order) {
    this.images = [];

    for (let i = 0; i < order.OrderElements.length; i++) {
      this.images.push(
        new Promise<SafeResourceUrl>((resolve, reject) => {
          const postdata: Postdata<OrderElement> = new Postdata<OrderElement>();
          postdata.data = order.OrderElements[i];

          const form = new FormData();
          form.append('data', JSON.stringify(postdata));

          this.http
            .post('./api/generate-thumbnail/', form, { responseType: 'blob' })
            .subscribe((result) => {
              const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(result)
              );
              resolve(imgurl);
            });
        })
      );
    }
  }

  ngOnDestroy() {
    if (this.dialogSub != undefined) this.dialogSub.unsubscribe();

    if (this.libSub != undefined) this.libSub.unsubscribe();

    if (this.orderSub != undefined) this.orderSub.unsubscribe();
  }

  goBack() {
    this.router.navigate(['/', 'config']);
  }

  getThumbnailUrl(orderElement: OrderElement): string {
    const json = JSON.stringify(orderElement);

    //btoa = to base 64 string... Quite a descriptive method name isn't it? :P
    //fyi, decoding base64 is atob :')
    return './api/generate-thumbnail/' + btoa(json);
  }

  getBrickLabel(code: string, verband: Verband): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.libService.getBrickLabel(code, verband, true)
    );
  }

  getVoegLabel(voeg: Voegkleur): string {
    return this.libService.getVoegLabel(voeg);
  }
  getVoegLabelWithID(voeg: Voegkleur): string {
    return this.libService.getVoegLabelWithID(voeg);
  }

  getVerbandLabel(verband: Verband): string {
    return this.libService.getVerbandLabel(verband);
  }

  getRooftileLabel(code: string): string {
    return this.libService.getRooftileLabel(code, true);
  }

  getRooftileDescription(code: string): string {
    return this.libService.getRooftileDescription(code);
  }

  clickedSend() {
    if (this.order == undefined) return;

    this.busy = true;

    const data: Postdata<Order> = new Postdata<Order>();
    data.data = this.order;

    const form = new FormData();
    form.append('data', JSON.stringify(data));

    this.http.post('./api/update-order/', form).subscribe((result) => {
      this.dialogVisible = true;
      this.busy = false;

      this.dialogService.show(
        'Bedankt voor het gebruiken van de Brick Configurator! Wij hebben uw keuze ontvangen, er wordt een mail naar u verzonden.',
        'terug naar home (' + this.dialogTimeout + ')'
      );

      this.countDown();
    });
  }

  dialogVisible: boolean = false;

  dialogStateChanged(state: boolean) {
    if (this.dialogVisible && !state) {
      this.dialogVisible = false;

      // console.log("dialog is closed");

      this.router.navigate(['/']);
    }
  }

  countDown() {
    if (this.dialogVisible && this.dialogTimeout > 0) {
      this.dialogTimeout -= 1;
      this.dialogService.updateOk(
        'terug naar home (' + this.dialogTimeout + ')'
      );
      setTimeout(() => {
        this.countDown();
      }, 1000);
    } else if (this.dialogVisible) {
      this.dialogService.hide(true);
    }
  }
}
