import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Voegkleur } from 'src/app/models/order';
import { Postdata } from 'src/app/models/postdata';
import { ReturnData } from 'src/app/models/returndata';

export class DownloadFile {
  public Label: string = "";
  public Voegkleur: string = "";
  public Legverband: string = "";
  public URL: string = "";
  public Filename: string = "";
}

export class ExportElement {

  public Basis: DownloadFile = new DownloadFile;
  public Accent: DownloadFile | null = null;
}

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {

  exportData?: ExportElement[] = undefined;

  selectedElement: DownloadFile[] = [];

  routeSub?: Subscription = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.paramMap.subscribe(map => {
      if (map.has("code")) {
        this.init(map.get("code"));
      } else {
        //no code - oh no!
        this.router.navigate(["/"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSub != undefined)
      this.routeSub.unsubscribe();
  }

  init(code: string | null) {

    if (code == null)
      return;
    const _code = <string>code;

    const postdata = new Postdata<string>();
    postdata.data = _code;
    const form = new FormData();
    form.append("data", JSON.stringify(postdata));
    this.http.post<ReturnData<ExportElement[]>>("./api/get-order-download-details/", form).subscribe((result) => {
      this.exportData = [];
      result.data.forEach((el) => {
        this.exportData?.push(Object.assign(new ExportElement, el));
      });
      // console.log(this.exportData);
    });
  }

  sanitize(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  clickedTexture(el: DownloadFile) {
    //TODO: we proposed to add a bump map as well
    //when client wants that, add bump url here
    // this.selected_downloads = [url, bump];
    this.selectedElement = [el];
  }

  clickedBack() {
    this.selectedElement = [];
  }

}
