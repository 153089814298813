import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {

  @Input("enabled") enabled: boolean = true;
  @Input("pill-class") pillClass: string = "";
  @Input("label") label: string = "";

  @Output("deleted") deleted = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  del() {
    if (this.enabled)
      this.deleted.emit();
  }
}
