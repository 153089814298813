import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from './dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  visible: boolean = false;

  message: string = "";
  ok: string = "";
  cancel: string = "";


  dialogContentSub?: Subscription = undefined;
  dialogSub?: Subscription = undefined;
  routerSub?: Subscription = undefined;

  constructor(private dialogService: DialogService, private router: Router) {

    this.routerSub = this.router.events.subscribe((val) => { this.onRouterEvent(val); });

    this.dialogSub = this.dialogService.isVisible.subscribe((state) => { this.dialogStateChanged(state); });
    this.dialogContentSub = this.dialogService.contentChanged.subscribe(() => {

      this.message = this.dialogService.message;
      this.ok = this.dialogService.ok;
      this.cancel = this.dialogService.cancel;

    });
  }

  onRouterEvent(val: Event) {
    if (val instanceof NavigationEnd && this.visible) {
      this.dialogService.hide(false);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.dialogContentSub != undefined)
      this.dialogContentSub.unsubscribe()
    if (this.dialogSub != undefined)
      this.dialogSub.unsubscribe()
    if (this.routerSub != undefined)
      this.routerSub.unsubscribe()
  }

  dialogStateChanged(state: boolean) {

    this.visible = state;

    if (state) {
      this.message = this.dialogService.message;
      this.ok = this.dialogService.ok;
      this.cancel = this.dialogService.cancel;
    }
  }

  clickedOk() {
    this.dialogService.hide(true);
  }

  clickedCancel() {
    this.dialogService.hide(false);
  }

}
