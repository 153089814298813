<app-sidebar class="full-frame" #sidebar>
    <div slot="frame" class="frame-content">

        <div class="result-container" *ngIf="filteredRooftiles != undefined && filteredRooftiles.length > 0">
            <app-logos [right]="false" class="full-frame"></app-logos>
            <div class="result-counter" #resultcontainer>
                <span class="bold">{{filteredRooftiles.length}}</span> Resultaten
            </div>

            <div class="results">

                <div class="brick"
                    *ngFor="let rooftile of filteredRooftiles.slice(page*bricksPerPage, (page+1)*bricksPerPage); let index = index">
                    <div class="loading">
                        <svg fill="none" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10"
                                filter="url(#shadow)">
                            </circle>
                            <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10">
                            </circle>
                        </svg>
                    </div>
                    <img [src]="images[index] | async" />

                    <div class="brick-label">
                        <span class="label">
                            {{rooftile.Label}}
                        </span>
                        <a class="button red brick-button" (click)="done(rooftile.Code)">KIES</a>
                    </div>
                </div>

            </div>

            <div class="pages">
                <a class="hover" [ngClass]="{'disabled':!hasPreviousPage()}" (click)="previousPage()"><img
                        src="./assets/ui/icon-arrow-left.svg"></a>
                <span class="page-count">
                    pagina {{page+1}} van {{getTotalPagecount()}}
                </span>
                <a class="hover" [ngClass]="{'disabled':!hasNextPage()}" (click)="nextPage()"><img
                        src="./assets/ui/icon-arrow-right.svg"></a>
            </div>
        </div>
    </div>
    <div slot="sidebar" class="sidebar-content">

        <div class="tab-bar">
            <a class="tab" (click)="done('')">Configureer</a>
            <a class="tab active">Onze artikelen</a>
            <a class="tab" (click)="done('overzicht')">Mijn overzicht</a>
        </div>
        <div class="block header">

            <h4>Filters</h4>
            <div class="search">
                <img src="./assets/ui/ic_search_24px.svg">
                <input type="text" placeholder="Zoeken" [(ngModel)]="search_string"
                    (ngModelChange)="searchStringChanged($event)">
            </div>
        </div>


        <div class="block filter-dropdowns">

            <ng-template #footer>
                <!-- <div class="footer">
                <span>
                    FILTER
                </span>
            </div> -->
            </ng-template>
            <app-dropdown class="filterpage" [dropdown-data]="filterType" placeholder="Type" [multi-select]="true"
                [footer]="footer" (multiValueChanged)="filter()" arrowSrc="./assets/ui/icon-arrow-down.svg"
                uncheckedSrc="./assets/ui/icon-checkbox-unchecked.svg"
                checkedSrc="./assets/ui/icon-checkbox-checked.svg">
            </app-dropdown>
            <app-dropdown class="filterpage" [dropdown-data]="filterModel" placeholder="Model" [multi-select]="true"
                [footer]="footer" (multiValueChanged)="filter()" arrowSrc="./assets/ui/icon-arrow-down.svg"
                uncheckedSrc="./assets/ui/icon-checkbox-unchecked.svg"
                checkedSrc="./assets/ui/icon-checkbox-checked.svg">
            </app-dropdown>
            <app-dropdown class="filterpage right" [dropdown-data]="filterColor" placeholder="Kleur"
                [multi-select]="true" [footer]="footer" (multiValueChanged)="filter()"
                arrowSrc="./assets/ui/icon-arrow-down.svg" uncheckedSrc="./assets/ui/icon-checkbox-unchecked.svg"
                checkedSrc="./assets/ui/icon-checkbox-checked.svg">
            </app-dropdown>



        </div>

        <div class="block current-filters">

            <ng-container *ngFor="let model of filterModel">
                <app-pill *ngIf="model.checked" label="Model: {{model.label}}" (deleted)="model.checked=false;filter()">
                </app-pill>
            </ng-container>
            <ng-container *ngFor="let type of filterType">
                <app-pill *ngIf="type.checked" label="Type: {{type.label}}" (deleted)="type.checked=false;filter()">
                </app-pill>
            </ng-container>
            <ng-container *ngFor="let kleur of filterColor">
                <app-pill *ngIf="kleur.checked" label="Kleur: {{kleur.label}}" (deleted)="kleur.checked=false;filter()">
                </app-pill>
            </ng-container>

            <app-pill class="clear" [ngClass]="{'has-filters': hasFilters()}" label="wissen" [enabled]="hasFilters()"
                pill-class="dark" (deleted)="clearfilters()">
            </app-pill>

        </div>


        <div class="grow"></div>
        <div class="block back-row">
            <a #backbutton class="button red rounded small-ish" (click)="done('')">Terug naar configurator</a>
        </div>


    </div>