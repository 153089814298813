import { Prijsklasse, Steenformaat, Steenvorm, Verband, Voegkleur } from "./order";

export class BrickLibrary {

    stenen: Steen[] = [];
    dakpannen: Dakpan[] = [];
    verbanden: EnumWrapper<Verband>[] = [];
    steenformaten: EnumWrapper<Steenformaat>[] = [];
    steenvormen: EnumWrapper<Steenvorm>[] = [];
    prijsklasses: EnumWrapper<Prijsklasse>[] = [];
    voegen: EnumWrapper<Voegkleur>[] = [];
}

export class Steen {
    Code: string = "";
    Kleur: string = "";
    Steenformaat: Steenformaat = 0;
    Steenvorm: Steenvorm = 0;
    Prijsklasse: Prijsklasse = 0;
    Label: string = "";
    Leverancier: string = "";
    IsProjectsteen: boolean = false;
}

export class Dakpan {
    Code: string = "";
    Model: string = "";
    Label: string = "";
    Omschrijving: string = "";
    DakpanType: string = "";
    KleurLabel: string = "";

}

export interface EnumWrapper<T> {
    enum_value: T;
    label: string;
    id: string;
}