import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  static readonly KEY_CODE: string = "brickpoint-code";

  constructor() { }

  public clearState() {
    sessionStorage.clear();
  }

  getCode(): string | null {
    return sessionStorage.getItem(CodeService.KEY_CODE);
  }

  public markCodeAsValid(code: string) {
    sessionStorage.setItem(CodeService.KEY_CODE, code);

    //TODO: timeout of code valid
  }

  public isValid(): boolean {
    const storageItem = sessionStorage.getItem(CodeService.KEY_CODE);

    return storageItem != null && storageItem != "";
  }
}
