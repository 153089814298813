import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/models/helpers';
import { BrickLibrary } from 'src/app/models/library';
import {
  Order,
  OrderElement,
  SteenElement,
  Verband,
  Voegkleur,
} from 'src/app/models/order';
import { ReturnData } from 'src/app/models/returndata';
import { PadLeftPipe } from 'src/app/pipes/padleft.pipe';
import { LibraryService } from 'src/app/services/library.service';
import { OrderService } from 'src/app/services/order.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { DropdownData } from 'src/app/shared/dropdown/dropdown.component';
import { HintElement, HintService } from 'src/app/shared/hint/hint.service';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';

export class BrickTextureDetails {
  public brick_height: number = 0;
  public num_rows: number = 0;
  public aspect: number = 0;
  public accent_start: number = 1;
}

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('textureRoot', { static: false }) textureRoot?: ElementRef =
    undefined;
  @ViewChild('textureContainer', { static: false })
  textureContainer?: ElementRef = undefined;

  @ViewChild('collectionButton', { static: false })
  hint_collectionButton?: ElementRef = undefined;
  @ViewChild('generateButton', { static: false })
  hint_generateButton?: ElementRef = undefined;
  @ViewChild('addButton', { static: false }) hint_addButton?: ElementRef =
    undefined;
  @ViewChild('overviewButton', { static: false })
  hint_overviewButton?: ElementRef = undefined;

  @ViewChild('sidebar', { static: false }) sidebar?: SidebarComponent =
    undefined;

  tab: 'configure' | 'overzicht' = 'configure';

  readonly ACCENT_PERC = 0.7;
  readonly TEXTURE_SCALE: number = 0.5;

  readonly FADE_TIME: number = 500;

  collectionHint: HintElement = {
    id: 'config-0',
    message:
      'Gebruik de knop COLLECTIE om onze gevelstenen te bekijken en de gewenste keuze te selecteren.',
    getElement: () => this.hint_collectionButton,
    offset: { x: -100, y: -125 },
    displayed: false,
  };

  generateButtonHint: HintElement = {
    id: 'config-1',
    message:
      'Gebruik de knop GENEREER TEXTUUR om uw keuze te genereren. U kunt deze knop meerdere keren gebruiken om een andere samenstelling te creëren.',
    getElement: () => this.hint_generateButton,
    offset: { x: -200, y: 50 },
    displayed: false,
  };

  addButtonHint: HintElement = {
    id: 'config-2',
    message: 'De textuur kunt u toevoegen en opslaan.',
    getElement: () => this.hint_addButton,
    offset: { x: -90, y: -115 },
    displayed: false,
  };

  toOverviewHint: HintElement = {
    id: 'config-3',
    message:
      'Bent u tevreden met uw samenstellingen? Dan kunt u de knop NAAR OVERZICHT gebruiken om uw selecties te versturen naar uw e-mail.',
    getElement: () => this.hint_overviewButton,
    offset: { x: -71, y: -141 },
    displayed: false,
  };

  currentHint?: HintElement = undefined;

  //enums
  Verband = Verband;

  //material browser stuff
  browsingRoof: boolean = false;
  browsing: boolean = false;
  browseVoegkleur: Voegkleur = Voegkleur.Lichtgrijs;
  browseVerband: Verband = Verband.Halfsteens;

  //libary
  library?: BrickLibrary = undefined;
  order?: Order = undefined;

  //current selected orderElement
  orderElement: OrderElement = new OrderElement();
  existingElementRef?: OrderElement = undefined;

  voegKleuren: DropdownData[] = [];

  //texture generator
  textureDisplayReady: boolean = false;
  generating: boolean = false;
  basisSteenImageString: string = '';
  accentSteenImageString: string = '';
  roofImageString: string = '';

  basisIsProjectsteen: boolean = false;
  accentIsProjectsteen: boolean = false;

  // css styles
  textureContainerStyle: Object = {};
  basisContainerStyle: Object = {};
  accentContainerStyle: Object = {};
  roofContainerStyle: Object = {};

  //texture details from generator
  basisTextureDetails?: BrickTextureDetails;
  accentTextureDetails?: BrickTextureDetails;

  constructor(
    private libService: LibraryService,
    private orderService: OrderService,
    private http: HttpClient,
    private padPipe: PadLeftPipe,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private hintService: HintService,
    private sanitizer: DomSanitizer
  ) {}

  private hintSub?: Subscription = undefined;
  private orderSub?: Subscription = undefined;
  private libSub?: Subscription = undefined;

  ngOnInit(): void {
    this.orderSub = this.orderService.gotOrder.subscribe((order) => {
      this.order = order;

      this.libSub = this.libService.gotLibrary.subscribe((library) => {
        if (library != null) {
          this.library = library;
          this.fillVoegkleuren();
        }
      });
    });

    this.hintSub = this.hintService.isVisible.subscribe((state) => {
      this.hintStateChange(state);
    });

    this.updateTextureStyles();
  }

  ngOnDestroy() {
    if (this.hintSub != undefined) this.hintSub.unsubscribe();

    if (this.orderSub != undefined) this.orderSub.unsubscribe();
    if (this.libSub != undefined) this.libSub.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (
      !this.collectionHint.displayed &&
      this.collectionHint.getElement() != undefined
    )
      this.showHint(this.collectionHint);
  }

  hintStateChange(state: boolean) {
    if (state === false && this.currentHint != undefined) {
      if (
        this.currentHint == this.collectionHint &&
        this.generateButtonHint.displayed === false
      ) {
        this.showHint(this.generateButtonHint);
      }
    }
  }

  showHint(hint: HintElement) {
    if (hint.getElement() == undefined) return;
    this.currentHint = hint;
    this.hintService.show(hint);
  }

  fillVoegkleuren() {
    this.voegKleuren = [];

    this.library?.voegen.forEach((el) => {
      this.voegKleuren.push({
        internalvalue: el.enum_value,
        label: el.label + ' - ' + el.id,
        checked: false,
      });
    });
  }

  updateTextureStyles() {
    this.getTextureParentDivStyle();
    this.getBasisSteenDivStyle();
    this.getAccentSteenDivStyle();
    this.getRoofDivStyle();
  }

  getTextureParentDivStyle() {
    const size = this.getTextureContainerSquareSize();

    this.textureContainerStyle = {
      width: size + 'px',
      height: size + 'px',
      display: size == 0 ? 'none' : 'initial',
    };
  }

  getTextureContainerSquareSize(): number {
    if (
      this.textureRoot == undefined ||
      !this.textureDisplayReady ||
      this.generating
    )
      return 0;

    const textureRoot = <HTMLElement>this.textureRoot.nativeElement;

    const height = textureRoot.offsetHeight;
    const width = textureRoot.offsetWidth;

    //smallest size
    let size = height > width ? width : height;
    size -= 2; //remove 2 pixels for the border :P

    return size;
  }

  getRoofDivStyle() {
    if (this.textureRoot == undefined) return;

    this.roofContainerStyle = {
      'background-image': "url('" + this.roofImageString + "')",
      filter: 'opacity(' + (this.textureDisplayReady ? 1 : 0) + ')',
    };
  }

  getBasisSteenDivStyle() {
    if (this.textureRoot == undefined) return;

    const size = this.getTextureContainerSquareSize();

    this.basisContainerStyle = {
      'background-image': "url('" + this.basisSteenImageString + "')",
      'background-size': '' + Math.round(this.TEXTURE_SCALE * 100) + '% auto',
      filter: 'opacity(' + (this.textureDisplayReady ? 1 : 0) + ')',
    };

    if (
      this.accentTextureDetails == undefined &&
      this.basisTextureDetails != undefined &&
      this.textureDisplayReady
    ) {
      //only basis steen, no accent. scale to fit full rows
      const aspect = this.basisTextureDetails.aspect;
      const repeatX = 1 / this.TEXTURE_SCALE;
      const repeatY = repeatX * aspect;

      const lines = repeatY * this.basisTextureDetails.num_rows;
      const wantedLines = Math.round(lines / 2) * 2;

      const perc = wantedLines / lines;
      const newHeight = size * perc;

      this.textureContainerStyle = {
        width: size + 'px',
        height: newHeight + 'px',
      };
    }
  }

  // accentPerc
  getAccentSteenDivStyle() {
    if (this.textureRoot == undefined) return;

    const size = this.getTextureContainerSquareSize();

    let y = '';
    let opacity = 0;
    let bgimage = '';
    let bgsize = '' + Math.round(this.TEXTURE_SCALE * 100) + '% auto';

    // console.log("bla");
    // console.log(this.basisTextureDetails);
    // console.log(this.accentTextureDetails);

    //only display if we have an accent
    if (
      this.accentTextureDetails != undefined &&
      this.basisTextureDetails != undefined &&
      this.textureDisplayReady
    ) {
      bgimage = "url('" + this.accentSteenImageString + "')";
      opacity = this.textureDisplayReady ? 1 : 0;

      //only basis steen, no accent. scale to fit full rows
      const aspect = this.basisTextureDetails.aspect;
      const repeatX = 1 / this.TEXTURE_SCALE;
      const repeatY = repeatX * aspect;

      const totalLinesMain = repeatY * this.basisTextureDetails.num_rows;
      const wantedLinesMain =
        Math.round((totalLinesMain * this.ACCENT_PERC) / 2) * 2;
      const percLinesMain = wantedLinesMain / totalLinesMain;
      // const newHeight = size * perc;
      // console.log("with accent perc of " + this.ACCENT_PERC + " and repeat " + repeatY + " gives total lines of " + totalLinesMain
      //   + " and " + wantedLinesMain + " wanted main lines. Rest should be accent");

      const pixelPerLine = size / totalLinesMain;
      const ypos = pixelPerLine * wantedLinesMain;
      y = ypos + 'px';

      //now, to fit the container to end with a full line
      const leftoverHeight = size - ypos;
      const repeatYAccent = repeatX * this.accentTextureDetails.aspect; // for a full image
      const totalLinesAccent =
        repeatYAccent * this.accentTextureDetails.num_rows;
      const pixelPerLineAccent = size / totalLinesAccent;
      const visibleLinesNow = leftoverHeight / pixelPerLineAccent;
      const wantedVisibleLines = Math.round(visibleLinesNow / 2) * 2;
      const wantedLeftoverHeight = wantedVisibleLines * pixelPerLineAccent;
      const diff = wantedLeftoverHeight - leftoverHeight;

      const newYSize = size + diff;

      // console.log("with accent repeat " + repeatYAccent + " and leftover " + leftoverHeight
      //   + " there are " + visibleLinesNow + " accent lines visible. Wanted: " + wantedVisibleLines
      //   + ". gives height of " + wantedLeftoverHeight + " and diff " + diff +
      //   " so should go from height " + size + " to " + newYSize);

      this.textureContainerStyle = {
        width: size + 'px',
        height: newYSize + 'px',
      };
    }

    this.accentContainerStyle = {
      'background-image': bgimage,
      'background-size': bgsize,
      top: y,
      filter: 'opacity(' + opacity + ')',
    };
  }

  public getBrickLabel(code: string, verband: Verband): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.libService.getBrickLabel(code, verband, true)
    );
  }

  public getVoegLabel(voeg: Voegkleur): string {
    return this.libService.getVoegLabel(voeg);
  }

  public getVoegLabelWithID(voeg: Voegkleur): string {
    return this.libService.getVoegLabelWithID(voeg);
  }

  public getVerbandLabel(verband: Verband): string {
    return this.libService.getVerbandLabel(verband);
  }

  getRooftileLabel(code: string, bolded: boolean): string {
    return this.libService.getRooftileLabel(code, bolded);
  }

  getRooftileDescription(code: string): string {
    return this.libService.getRooftileDescription(code);
  }

  onBrowseDone(code: string) {
    if (code != '' && code != 'overzicht' && this.browseElement != undefined) {
      this.browseElement.Code = code;
      this.clickedGenerate();
    } else if (code == 'overzicht') this.tab = 'overzicht';
    else this.tab = 'configure';

    this.browsing = false;
    this.browseElement = undefined;
  }

  clickedOverzicht() {
    this.router.navigate(['/', 'config', 'overview']);
  }

  clickedAdd() {
    if (!this.textureDisplayReady) return;

    //new order
    if (this.existingElementRef == undefined) {
      this.addNewOrderElement();
    } else {
      this.editExistingOrderElement();
    }

    if (this.sidebar != undefined) this.sidebar.open();
  }

  clickedEditExisting(el: OrderElement) {
    //make a copy
    this.orderElement = <OrderElement>JSON.parse(JSON.stringify(el));
    this.existingElementRef = el;
    this.clickedGenerate();
  }

  clickedDeleteExisting(el: OrderElement) {
    if (this.existingElementRef == el) this.existingElementRef = undefined;

    if (this.order == undefined) return;

    const index = this.order.OrderElements.indexOf(el);

    if (index > -1) {
      this.order.OrderElements.splice(index, 1);
      this.orderService.save();
    }
  }

  addNewOrderElement() {
    if (this.order == undefined) return;

    if (!this.findElementInCurrentOrder(this.orderElement)) {
      this.order.OrderElements.push(this.orderElement);
      this.orderService.save();
      this.clickedReset();

      if (this.toOverviewHint.displayed === false)
        this.showHint(this.toOverviewHint);
    } else {
      //existing one
      this.dialogService.show(
        'Error. De opgegeven combinatie van gevelsteen en accentsteen bestaat al in uw order.',
        'sluiten'
      );
    }
  }

  editExistingOrderElement() {
    if (this.order == undefined || this.existingElementRef == undefined) return;

    if (
      !this.findElementInCurrentOrder(
        this.orderElement,
        this.existingElementRef
      )
    ) {
      const index = this.order.OrderElements.indexOf(this.existingElementRef);

      if (index > -1) {
        //replace
        this.order.OrderElements[index] = this.orderElement;
        this.orderService.save();
        this.clickedReset();

        if (this.toOverviewHint.displayed === false)
          this.showHint(this.toOverviewHint);
      }
    } else {
      //existing one
      this.dialogService.show(
        'Error. De opgegeven combinatie van gevelsteen en accentsteen bestaat al in uw order.',
        'sluiten'
      );
    }
  }

  findElementInCurrentOrder(
    el: OrderElement,
    ignore: OrderElement | undefined = undefined
  ): boolean {
    let foundElement = undefined;

    if (this.order != undefined) {
      for (let i = 0; i < this.order.OrderElements.length; i++) {
        const other = this.order.OrderElements[i];

        if (!el.IsDakpan) {
          //compare
          if (
            other.IsDakpan == el.IsDakpan &&
            other.SteenElementBasis.Code == el.SteenElementBasis.Code &&
            other.SteenElementBasis.Voegkleur ==
              el.SteenElementBasis.Voegkleur &&
            other.SteenElementBasis.KlantNotitie ==
              el.SteenElementBasis.KlantNotitie &&
            other.SteenElementAccent.Code == el.SteenElementAccent.Code &&
            other.SteenElementAccent.Voegkleur ==
              el.SteenElementAccent.Voegkleur &&
            other.SteenElementAccent.KlantNotitie ==
              el.SteenElementAccent.KlantNotitie &&
            other.Verband == el.Verband &&
            other != ignore
          ) {
            foundElement = other;
            break;
          }
        } else {
          //compare
          if (
            other.IsDakpan == el.IsDakpan &&
            other.DakElement.Code == el.DakElement.Code &&
            other != ignore
          ) {
            foundElement = other;
            break;
          }
        }
      }
    }

    return foundElement != undefined;
  }
  basisSteenValChange($event: string) {
    this.basisIsProjectsteen = false;
  }

  accentSteenValChange($event: string) {
    this.accentIsProjectsteen = false;
  }

  clickedGenerate() {
    if (this.library == undefined) return;

    if (this.orderElement.IsDakpan) this.generateDakpan();
    else this.generateBaksteen();
  }

  beforeGenerateBeforeFadeInit() {
    this.textureDisplayReady = false;
    this.generating = true;
    this.basisTextureDetails = undefined;
    this.accentTextureDetails = undefined;
    this.updateTextureStyles();

    if (this.sidebar != undefined) {
      this.sidebar.close();
    }
    console.log('fade out');
  }

  beforeGEnerateAfterFadeInit() {
    this.accentSteenImageString = '';
    this.basisSteenImageString = '';
    this.roofImageString = '';
  }

  generateDakpan() {
    if (this.library == undefined) return;

    console.log(this.orderElement.DakElement.Code);

    const libElement = this.library.dakpannen.find(
      (x) => x.Code == this.orderElement.DakElement.Code
    );

    if (libElement == undefined) {
      this.dialogService.show(
        'De opgegeven dakpan is niet gevonden',
        'sluiten'
      );
      return;
    }

    this.beforeGenerateBeforeFadeInit();

    setTimeout(() => {
      this.beforeGEnerateAfterFadeInit();
      if (this.library == undefined) return;

      console.log('start generate');

      const url =
        './api/generate-roof-texture/' +
        this.orderElement.DakElement.Code +
        '/';

      this.http.get(url, { responseType: 'blob' }).subscribe((result) => {
        this.roofImageString = URL.createObjectURL(result);
        console.log(this.basisSteenImageString);
        console.log(this.accentSteenImageString);
        console.log(this.roofImageString);
        this.changeRef.detectChanges();
        this.readyGenerate();
      });
    }, this.FADE_TIME);
  }

  generateBaksteen() {
    if (this.library == undefined) return;

    //pre-check
    const mainData = this.library.stenen.find(
      (x) => x.Code == this.orderElement.SteenElementBasis.Code
    );
    const accentData = this.library.stenen.find(
      (x) => x.Code == this.orderElement.SteenElementAccent.Code
    );

    if (mainData == undefined) {
      //error out
      console.log('invalid main');
      this.dialogService.show(
        'De door u opgegeven productcode voor gevelsteen is niet gevonden',
        'sluiten'
      );
      return;
    }

    if (accentData == null && this.orderElement.SteenElementAccent.Code != '') {
      //error out
      console.log('invalid accent');
      this.dialogService.show(
        'De door u opgegeven productcode voor accentsteen is niet gevonden',
        'sluiten'
      );
      return;
    }

    this.basisIsProjectsteen =
      mainData != undefined ? mainData.IsProjectsteen : false;
    this.accentIsProjectsteen =
      accentData != undefined ? accentData.IsProjectsteen : false;

    console.log(
      'determined projectsteen - main: ' +
        this.basisIsProjectsteen +
        ', accent ' +
        this.accentIsProjectsteen
    );

    this.beforeGenerateBeforeFadeInit();

    setTimeout(() => {
      this.beforeGEnerateAfterFadeInit();

      if (this.library == undefined) return;

      console.log('start generate');

      //generate random number
      let random = Math.floor(Math.random() * 9999);
      this.orderElement.Seed = this.padPipe.transform(random.toString(), 4);

      //fetch images and set
      const url = './api/generate-texture/' + this.orderElement.Seed + '/';

      //fetch details for main brick

      this.http
        .get<ReturnData<BrickTextureDetails>>(
          './api/get-brick-texture-details/' +
            this.orderElement.Seed +
            '/' +
            mainData.Code +
            '/' +
            this.orderElement.Verband +
            '/' +
            this.orderElement.SteenElementBasis.Voegkleur
        )
        .subscribe((basisTextureDetailsResult) => {
          this.basisTextureDetails = basisTextureDetailsResult.data;

          //fetch texture for main brick
          this.http
            .get(
              url +
                mainData.Code +
                '/' +
                this.orderElement.Verband +
                '/' +
                this.orderElement.SteenElementBasis.Voegkleur,
              { responseType: 'blob' }
            )
            .subscribe((result) => {
              this.basisSteenImageString = URL.createObjectURL(result);
              this.changeRef.detectChanges();

              //check for accentsteen
              if (
                accentData != null &&
                this.orderElement.SteenElementAccent.Code != ''
              ) {
                //fetch accent texture details
                this.http
                  .get<ReturnData<BrickTextureDetails>>(
                    './api/get-brick-texture-details/' +
                      this.orderElement.Seed +
                      '/' +
                      accentData.Code +
                      '/' +
                      this.orderElement.Verband +
                      '/' +
                      this.orderElement.SteenElementAccent.Voegkleur
                  )
                  .subscribe((accentResult) => {
                    this.accentTextureDetails = accentResult.data;

                    //fetch accent texture

                    this.http
                      .get(
                        url +
                          accentData.Code +
                          '/' +
                          this.orderElement.Verband +
                          '/' +
                          this.orderElement.SteenElementAccent.Voegkleur,
                        { responseType: 'blob' }
                      )
                      .subscribe((result) => {
                        this.accentSteenImageString =
                          URL.createObjectURL(result);
                        this.changeRef.detectChanges();
                        this.readyGenerate();
                      });
                  });
              } else {
                this.accentSteenImageString = '';
                this.readyGenerate();
              }
            });
        });
    }, this.FADE_TIME);
  }

  readyGenerate() {
    console.log('got done');
    setTimeout(() => {
      console.log('fade in');
      this.generating = false;
      this.textureDisplayReady = true;
      this.updateTextureStyles();

      if (this.addButtonHint.displayed === false)
        this.showHint(this.addButtonHint);
    }, this.FADE_TIME * 2);
  }

  clickedReset() {
    this.textureDisplayReady = false;
    this.existingElementRef = undefined;
    this.orderElement = new OrderElement();
    this.updateTextureStyles();
  }

  browseElement?: SteenElement = undefined;
  clickedCollection(browseElement: SteenElement) {
    this.browseElement = browseElement;
    this.browseVoegkleur = this.browseElement.Voegkleur;
    this.browseVerband = this.orderElement.Verband;

    this.browsing = true;
  }

  clickedCollectionRoof() {
    this.browseRoofModel = '';
    this.browseRoofType = '';
    if (this.library == undefined) return;

    if (this.orderElement.DakElement.Code != '') {
      const data = this.library.dakpannen.find(
        (x) => x.Code == this.orderElement.DakElement.Code
      );
      if (data != undefined) {
        this.browseRoofModel = data.Model;
        this.browseRoofType = data.DakpanType;
      }
    }
    this.browsingRoof = true;
  }

  onBrowseRoofDone(code: string) {
    if (code != '' && code != 'overzicht' && this.orderElement != undefined) {
      this.orderElement.DakElement.Code = code;
      this.clickedGenerate();
    } else if (code == 'overzicht') this.tab = 'overzicht';
    else this.tab = 'configure';

    this.browsingRoof = false;
  }

  browseRoofType: string = '';
  browseRoofModel: string = '';
}
