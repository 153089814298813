import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CodeService } from '../services/code.service';

@Injectable({
  providedIn: 'root'
})
export class CodeGuard implements CanActivate {

  constructor(private stateService: CodeService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.stateService.isValid())
      return true;
    else
      return this.router.parseUrl("/");


  }

}
