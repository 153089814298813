export class Order {
    ProjectNumber: number = -1;
    Client: Client = new Client();
    Project: Project = new Project();
    OrderElements: OrderElement[] = [];
    DateCreated: string = "";
    Version: number = -1;
    DateLastEdited: string = "";
    OnlineCode: string = "";
    OnlineValidUntil: string = "";
    Steensample: boolean = false;
    Daksample: boolean = false;
    Adviseur: string = "";
}

export class Client {
    Bedrijf: string = "";
    ContactpersoonGender: Gender = 2;
    ContactpersoonVoornaam: string = "";
    ContactpersoonAchternaam: string = "";
    Straat: string = "";
    Huisnummer: string = "";
    Postcode: string = "";
    Plaats: string = "";
    EmailAdres: string = "";
    Telefoonnummer: string = "";
}

export class Project {
    Plaats: string = "";
    Projectnaam: string = "";
    Opdrachtgever: string = "";
    Architect: string = "";
    Aannemer: string = "";
}

export class OrderElement {
    Verband: Verband = Verband.Halfsteens;
    SteenElementBasis: SteenElement = new SteenElement();
    SteenElementAccent: SteenElement = new SteenElement();
    IsDakpan: boolean = false;
    DakElement: DakElement = new DakElement();
    Seed: string = "0";
}

export class SteenElement {
    Code: string = "";
    Voegkleur: Voegkleur = Voegkleur.Lichtgrijs;
    KlantNotitie: string = "";
}

export class DakElement {
    Code: string = "";
    KlantNotitie: string = "";
}

export enum Gender { Male = 0, Female = 1, Unknown = 2 }
export enum Verband { Halfsteens = 0, Wild = 1 }
export enum Steenformaat { Waalformaat = 0, Dikformaat = 1 }
export enum Steenvorm { Handvorm = 0, Vormbak = 1, Wasserstrich = 2, Strengpers = 3, Alpha = 4 } //remove alpha later, this is a dev-voeg
export enum Prijsklasse { Tot500 = 0 }
export enum Voegkleur { Antraciet = 0, Bruin = 1, Creme = 2, Donkergrijs = 3, Geel = 4, Lichtgrijs = 5, Oranje = 6, Rood = 7, Wit = 8, Zwart = 9 }
