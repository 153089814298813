import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';

export interface Coords { x: number; y: number }

export interface HintElement {
  message: string;
  getElement(): ElementRef | undefined;
  offset: Coords;
  displayed: boolean;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class HintService {
  private readonly STORAGE_KEY_PREFIX: string = "hint_";
  private readonly STORAGE_CONTENT: string = "1";

  public isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public message: string = "";
  public ref?: ElementRef = undefined;
  public offset?: Coords;

  constructor(private order: OrderService) { }

  public show(hintElement: HintElement): void {

    if (hintElement == undefined)
      return;

    const storageElement = sessionStorage.getItem(this.STORAGE_KEY_PREFIX + hintElement.id);

    if (storageElement != undefined && storageElement == this.STORAGE_CONTENT) {
      //already displayed before in this session
      return;
    }

    const order = this.order.gotOrder.value;
    if (order != undefined && order.Project.Projectnaam.toLowerCase().indexOf("intern") > -1) {
      console.log("skipping hints for INTERNAL orders");
      sessionStorage.setItem(this.STORAGE_KEY_PREFIX + hintElement.id, this.STORAGE_CONTENT);
      return;
    }

    // console.log("show hint " + hintElement.message);
    this.message = hintElement.message;
    this.ref = hintElement.getElement();
    this.offset = hintElement.offset;
    hintElement.displayed = true;
    sessionStorage.setItem(this.STORAGE_KEY_PREFIX + hintElement.id, this.STORAGE_CONTENT);


    this.isVisible.next(true);
  }

  public hide(): void {
    // console.log("Hide hint");
    this.ref = undefined;
    this.message = "";
    this.offset = undefined;

    this.isVisible.next(false);
  }
}
