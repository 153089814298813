import { Component, HostListener, OnInit } from '@angular/core';
import { MediaQueryService } from './services/media-query.service';
import { HintService } from './shared/hint/hint.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><app-hint></app-hint><app-dialog></app-dialog><universal-cookie-consent></universal-cookie-consent>',
  styles: ['']
})
export class AppComponent {

  constructor(private mediaQueryService: MediaQueryService) {
    this.mediaQueryService.init();

  }

  // @HostListener('scroll', ['$event'])
  // onScroll(event: Event) {
  //   console.log("wheel");
  // }


  @HostListener('wheel', ['$event']) windowWheel(event: WheelEvent): void {
    // console.log("wheel");
    // console.log(event);
    if (event.ctrlKey) {
      // console.log("wheel");
      event.preventDefault();
    } else {
      // console.log(event);
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    if (event.ctrlKey == true
      &&
      (event.keyCode == 61
        || event.keyCode == 107
        || event.keyCode == 173
        || event.keyCode == 109
        || event.keyCode == 187
        || event.keyCode == 189)) {
      event.preventDefault();
    }
    // 107 Num Key  +
    // 109 Num Key  -
    // 173 Min Key  hyphen/underscore key
    // 61 Plus key  +/= key
  }


}
