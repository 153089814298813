<div class="dialog-overlay" *ngIf="visible">

    <div class="dialog-content">
        <span class="desc">
            {{message}}
        </span>

        <div class="buttons">
            <a class="button rounded red small-ish" (click)="clickedOk()">
                {{ok}}
            </a>
            <a class="button rounded white-outline small-ish" *ngIf="cancel != ''" (click)="clickedCancel()">
                {{cancel}}
            </a>
        </div>
    </div>
</div>