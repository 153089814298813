import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeGuard } from './guards/code.guard';
import { ConfigComponent } from './pages/config/config/config.component';
import { ConfigbaseComponent } from './pages/config/configbase.component';
import { DownloadComponent } from './pages/download/download.component';
import { ExtensionComponent } from './pages/extension/extension.component';
import { HomeComponent } from './pages/home/home.component';
import { OverviewComponent } from './pages/config/overview/overview.component';

const routes: Routes = [
  { path: 'download/:code', component: DownloadComponent },
  { path: 'extension/:code', component: ExtensionComponent },
  {
    path: 'config', component: ConfigbaseComponent, children: [
      { path: 'overview', component: OverviewComponent },
      { path: '*', redirectTo: '' },
      { path: '', component: ConfigComponent },
    ], canActivate: [CodeGuard]
  },
  { path: '*', redirectTo: '' },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
