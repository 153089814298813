import { Verband, Steenformaat, Steenvorm, Gender } from "./order";

export module Helpers {

    export function VerbandShort(val: Verband): string {
        switch (val) {
            case Verband.Halfsteens:
                return "HV";
            case Verband.Wild:
                return "WV";
        }

        return "";
    };

    export function SteenformaatShort(val: Steenformaat): string {
        switch (val) {
            case Steenformaat.Waalformaat:
                return "WF";
            case Steenformaat.Dikformaat:
                return "DF";
        }

        return "";
    };


    export function SteenvormShort(val: Steenvorm): string {
        switch (val) {
            case Steenvorm.Handvorm:
                return "HV";
            case Steenvorm.Vormbak:
                return "VB";
            case Steenvorm.Wasserstrich:
                return "WS";
            case Steenvorm.Strengpers:
                return "SP";
            case Steenvorm.Alpha:
                return "A";
        }

        return "";
    };


    export function getGenderString(gender: Gender): string {
        switch (gender
        ) {
            case Gender.Male:
                return "De heer";
            case Gender.Female:
                return "Mevrouw";
            default:
                return "";
        }
    }
}