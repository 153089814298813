<a class="dropdown" (click)="clickedDropdown()" [ngClass]="{'open': open}">
    <span class="selected-value">
        {{getLabel()}}
    </span>
    <img class="arrow" [src]="arrowSrc">
</a>
<div class="blocker" (click)="clickedBlocker()" *ngIf="open"></div>
<div class="dropdown-list" [ngClass]="{'open': open}">
    <a class="dropdown-item" *ngFor="let item of dropdownData" (click)="clickedValue(item)">
        <img *ngIf="(!multiSelect && item.internalvalue == value) || (multiSelect && item.checked)" [src]="checkedSrc">
        <img *ngIf="(!multiSelect && item.internalvalue != value) || (multiSelect && !item.checked)"
            [src]="uncheckedSrc">
        <span class="selected-value">{{item.label}}</span>
    </a>

    <ng-container *ngTemplateOutlet="footer"></ng-container>
</div>