import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/models/order';
import { Postdata } from 'src/app/models/postdata';
import { ReturnData } from 'src/app/models/returndata';

export class ExtensionPostdata {
  OnlineCode: string = "";
  Allow: boolean = false;
}

@Component({
  selector: 'app-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss']
})
export class ExtensionComponent implements OnInit, OnDestroy {

  code: string = '';
  order?: Order = undefined;
  error: boolean = false;
  busy: boolean = false;
  done: boolean = false;

  routeSub?: Subscription = undefined;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.routeSub = this.route.paramMap.subscribe(map => {
      if (map.has("code")) {
        this.init(map.get("code"));
      } else {
        //no code - oh no!
        this.router.navigate(["/"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSub != undefined)
      this.routeSub.unsubscribe();
  }

  init(code: string | null) {
    if (this.code == null)
      return;
    const _code = <string>code;

    this.code = _code;

    const postdata = new Postdata<string>();
    postdata.data = _code;
    const form = new FormData();
    form.append("data", JSON.stringify(postdata));
    this.http.post<ReturnData<Order>>("./api/get-order-from-code/", form).subscribe((result) => {

      if (result.success)
        this.order = result.data;
      else {
        this.order = undefined;
        this.error = true;
      }
    });
  }

  send(val: boolean) {
    if (this.busy)
      return;

    this.busy = true;

    const postdata = new Postdata<ExtensionPostdata>();
    postdata.data = { OnlineCode: this.code, Allow: val };

    const form = new FormData();
    form.append("data", JSON.stringify(postdata));

    this.http.post<ReturnData<Order>>("./api/extension/", form).subscribe((result) => {
      this.done = true;
      this.busy = false;
    });

  }

}
