import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrickLibrary, Steen } from 'src/app/models/library';
import {
  OrderElement,
  Prijsklasse,
  Steenformaat,
  Steenvorm,
  Verband,
  Voegkleur,
} from 'src/app/models/order';
import { LibraryService } from 'src/app/services/library.service';
import { filter } from 'rxjs/operators';
import { from, Subscription } from 'rxjs';
import {
  Coords,
  HintElement,
  HintService,
} from 'src/app/shared/hint/hint.service';
import { DropdownData } from 'src/app/shared/dropdown/dropdown.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Postdata } from 'src/app/models/postdata';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
})
export class BrowseComponent
  implements OnInit, AfterViewChecked, OnChanges, OnDestroy
{
  @ViewChild('resultcontainer', { static: false })
  resultcontainer?: ElementRef = undefined;
  @ViewChild('backbutton', { static: false }) backbutton?: ElementRef =
    undefined;

  private library?: BrickLibrary = undefined;

  @Input('enabled') enabled: boolean = false;
  @Input('verband') verband: Verband = 0;
  @Input('voegkleur') voegkleur: Voegkleur = 0;

  @Output('onBrowseDone') onBrowseDone: EventEmitter<string> =
    new EventEmitter<string>();

  //filters
  filterColor: DropdownData[] = [];
  filterSteenvorm: DropdownData[] = [];
  filterSteenformaat: DropdownData[] = [];
  filterPrice: DropdownData[] = [];

  filteredBricks: Steen[] = [];

  //paging
  bricksPerPage: number = 9;
  // bricksPerPage: number = 250;
  page: number = 0;

  initialized: boolean = false;

  search_string: string = '';

  images: Promise<SafeResourceUrl>[] = [];

  hints: HintElement[] = [
    {
      id: 'browse-0',
      message:
        'Bekijk hier al onze gevelstenen, u kunt de knop KIES gebruiken om de gevelsteen te selecteren.',
      getElement: () => undefined,
      offset: { x: 0, y: 0 },
      displayed: false,
    },
    {
      id: 'browse-1',
      message: 'U kunt te allen tijde terug naar de configurator.',
      getElement: () => this.backbutton,
      offset: { x: 0, y: 50 },
      displayed: false,
    },
  ]; //
  hintIndex = -1;

  constructor(
    private libService: LibraryService,
    private hintService: HintService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled) {
      this.filter();
      window.scroll(0, 0);
      if (this.initialized && this.hintIndex == -1) {
        this.showHint(0);
      }
    }

    // console.log("browse changes");
    // console.log(changes);
    // console.log(this.filterColor);
  }

  ngAfterViewChecked(): void {}

  showHint(index: number) {
    const hint = this.hints[index];
    this.hintIndex = index;

    this.hintService.show(hint);
  }

  hintStateChanged(state: boolean) {
    if (
      state == false &&
      this.hintIndex > -1 &&
      this.hintIndex < this.hints.length - 1
    ) {
      //next hint
      this.showHint(this.hintIndex + 1);
    }
  }

  hintSub?: Subscription = undefined;
  libSub?: Subscription = undefined;

  ngOnDestroy() {
    // console.log("browse on destroy");
    if (this.hintSub != undefined) this.hintSub.unsubscribe();

    if (this.libSub != undefined) this.libSub.unsubscribe();
  }

  ngOnInit(): void {
    this.hintSub = this.hintService.isVisible.subscribe((state) => {
      this.hintStateChanged(state);
    });

    this.libSub = this.libService.gotLibrary.subscribe((library) => {
      if (library != null) {
        this.library = library;
        // console.log(this.library);
        this.fillPossibleFilters();
        this.filter();
        this.initialized = true;
      }
    });
  }

  prepareThisPageImages() {
    this.images = [];

    if (this.library == undefined) return;

    // console.log("prepare thumbs");

    for (
      let i = this.page * this.bricksPerPage;
      i < this.page * this.bricksPerPage + this.bricksPerPage;
      i++
    ) {
      if (i >= this.filteredBricks.length) break;

      const el = this.filteredBricks[i];

      // console.log("preparing thumb");
      // console.log(el);
      this.images.push(
        new Promise<SafeResourceUrl>((resolve, reject) => {
          const postdata: Postdata<OrderElement> = new Postdata<OrderElement>();
          const oe = new OrderElement();
          oe.IsDakpan = false;
          oe.SteenElementBasis.Code = el.Code;
          oe.Verband = this.verband;
          oe.SteenElementBasis.Voegkleur = this.voegkleur;
          postdata.data = oe;

          const form = new FormData();
          form.append('data', JSON.stringify(postdata));

          // console.log("pre-fetch thumb \n" + JSON.stringify(postdata));

          this.http
            .post('./api/generate-thumbnail/', form, { responseType: 'blob' })
            .subscribe((result) => {
              const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(result)
              );
              resolve(imgurl);
            });
        })
      );
    }
  }

  fillPossibleFilters() {
    if (this.library == undefined) {
      return;
    }

    //colors is a special one - we need to fetch it from the available bricks, we
    //don't have a ready made list for it
    this.filterColor = [];
    const colors = this.library.stenen.map((x) => x.Kleur);
    colors.forEach((color) => {
      if (this.filterColor.findIndex((x) => x.internalvalue == color) == -1)
        this.filterColor.push({
          internalvalue: color,
          label: color,
          checked: false,
        });
    });

    //all the others are enums
    this.filterPrice = [];
    this.library.prijsklasses.forEach((prijsklasse) => {
      this.filterPrice.push({
        internalvalue: prijsklasse.enum_value,
        label: prijsklasse.label,
        checked: false,
      });
    });

    this.filterSteenformaat = [];
    this.library.steenformaten.forEach((steenformaat) => {
      this.filterSteenformaat.push({
        internalvalue: steenformaat.enum_value,
        label: steenformaat.label,
        checked: false,
      });
    });

    this.filterSteenvorm = [];
    this.library.steenvormen.forEach((steenvorm) => {
      this.filterSteenvorm.push({
        internalvalue: steenvorm.enum_value,
        label: steenvorm.label,
        checked: false,
      });
    });
  }

  hasFilters(): boolean {
    return (
      this.filterColor.find((x) => x.checked === true) != undefined ||
      this.filterPrice.find((x) => x.checked === true) != undefined ||
      this.filterSteenformaat.find((x) => x.checked === true) != undefined ||
      this.filterSteenvorm.find((x) => x.checked === true) != undefined
    );
  }

  clearfilters() {
    if (!this.hasFilters()) return;

    for (let i = 0; i < this.filterColor.length; i++)
      this.filterColor[i].checked = false;
    for (let i = 0; i < this.filterPrice.length; i++)
      this.filterPrice[i].checked = false;
    for (let i = 0; i < this.filterSteenformaat.length; i++)
      this.filterSteenformaat[i].checked = false;
    for (let i = 0; i < this.filterSteenvorm.length; i++)
      this.filterSteenvorm[i].checked = false;

    this.filter();
  }

  filter() {
    // console.log("filter");
    if (this.library == undefined) return;

    if (!this.hasFilters() && this.isEmptyOrSpaces(this.search_string)) {
      this.filteredBricks = this.library.stenen;
    } else {
      //check filters
      this.filteredBricks = [];

      for (let i = 0; i < this.library.stenen.length; i++) {
        const steen = this.library?.stenen[i];

        //either this stones color should be checked, or none should be checked
        const colorMatch =
          this.filterColor.find(
            (x) => x.internalvalue == steen.Kleur && x.checked === true
          ) != undefined ||
          this.filterColor.find((x) => x.checked) == undefined;

        const priceMatch =
          this.filterPrice.find(
            (x) => x.internalvalue == steen.Prijsklasse && x.checked === true
          ) != undefined ||
          this.filterPrice.find((x) => x.checked) == undefined;

        const formaatMatch =
          this.filterSteenformaat.find(
            (x) => x.internalvalue == steen.Steenformaat && x.checked === true
          ) != undefined ||
          this.filterSteenformaat.find((x) => x.checked) == undefined;

        const vormMatch =
          this.filterSteenvorm.find(
            (x) => x.internalvalue == steen.Steenvorm && x.checked === true
          ) != undefined ||
          this.filterSteenvorm.find((x) => x.checked) == undefined;

        const searchStringMatch =
          this.isEmptyOrSpaces(this.search_string) ||
          steen.Code.indexOf(this.search_string) == 0;

        if (
          colorMatch &&
          priceMatch &&
          formaatMatch &&
          vormMatch &&
          searchStringMatch
        ) {
          // console.log("match for steen " + steen.Code +
          //   ", color: (" + steen.Kleur + ")" + colorMatch +
          //   ", price: (" + steen.Prijsklasse + ")" + priceMatch +
          //   " formaat: (" + steen.Steenformaat + ")" + formaatMatch +
          //   " vorm: (" + steen.Steenvorm + ")" + vormMatch +
          //   " searchstring: (" + steen.Kleur + ")" + searchStringMatch
          // );

          this.filteredBricks.push(steen);
        } else {
          // console.log("no match for steen " + steen.Code +
          //   ", color: (" + steen.Kleur + ")" + colorMatch +
          //   ", price: (" + steen.Prijsklasse + ")" + priceMatch +
          //   " formaat: (" + steen.Steenformaat + ")" + formaatMatch +
          //   " vorm: (" + steen.Steenvorm + ")" + vormMatch +
          //   " searchstring: (" + steen.Kleur + ")" + searchStringMatch
          // );
        }
      }
    }

    this.page = 0;

    // console.log(this.filteredBricks);

    setTimeout(() => {
      this.prepareThisPageImages();
    }, 10);
  }
  isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
  }

  searchStringChanged($event: Event) {
    this.filter();
  }

  clear() {
    this.search_string = '';
    this.clearfilters();
  }

  public done(code: string) {
    this.clear();
    this.onBrowseDone.next(code);
  }

  // paging
  nextPage() {
    if (this.hasNextPage()) {
      this.page++;
      this.prepareThisPageImages();
      window.scroll(0, 0);
    }
  }

  previousPage() {
    if (this.hasPreviousPage()) {
      this.page--;
      this.prepareThisPageImages();
      window.scroll(0, 0);
    }
  }

  hasNextPage() {
    return (
      this.bricksPerPage + this.bricksPerPage * this.page <
      this.filteredBricks.length
    );
  }

  hasPreviousPage() {
    return this.page >= 1;
  }

  getTotalPagecount() {
    return Math.ceil(this.filteredBricks.length / this.bricksPerPage);
  }
}
