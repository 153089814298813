import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padleft'
})
export class PadLeftPipe implements PipeTransform {

  transform(value: string, count: number): string {
    return ("0".repeat(count) + value).slice(-count);
  }

}
