<app-logos [right]="false"></app-logos>

<app-sidebar *ngIf="order != undefined">

    <div slot="frame" class="frame-content">

        <span class="spec">
            Op uw verzoek sturen wij u de steenspecificaties toe
        </span>

        <div class="scroll-parent-flex desktop-only order-elements-container">
            <div class="scroll-container desktop-only order-elements">

                <div class="order-element" *ngFor="let element of order.OrderElements; let index = index">

                    <div class="number">
                        {{index + 1}}
                    </div>
                    <div class="preview">
                        <div class="loading">
                            <svg fill="none" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10"
                                    filter="url(#shadow)">
                                </circle>
                                <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10">
                                </circle>
                            </svg>
                        </div>
                        <img [src]="images[index] | async" />
                    </div>


                    <div class="details">
                        <h3 *ngIf="!element.IsDakpan">{{element.SteenElementBasis.Code}}</h3>
                        <h3 *ngIf="element.IsDakpan">{{element.DakElement.Code}}</h3>
                        <ng-container *ngIf="!element.IsDakpan">

                            <span>Gevelsteen:</span>
                            <span [innerHTML]="getBrickLabel(element.SteenElementBasis.Code, element.Verband)"></span>

                            <span>Voegkleur:</span>
                            <span>{{getVoegLabelWithID(element.SteenElementBasis.Voegkleur)}}</span>

                            <span>Metselverband:</span>
                            <span>{{getVerbandLabel(element.Verband)}}</span>

                            <span>Omschrijving:</span>
                            <span>{{element.SteenElementBasis.KlantNotitie}}</span>

                            <ng-container *ngIf="element.SteenElementAccent.Code != ''">

                                <!-- empty row -->
                                <span></span><span></span>

                                <span>Gevelsteen:</span>
                                <span
                                    [innerHTML]="getBrickLabel(element.SteenElementAccent.Code, element.Verband)"></span>

                                <span>Voegkleur:</span>
                                <span>{{getVoegLabelWithID(element.SteenElementAccent.Voegkleur)}}</span>

                                <span>Metselverband:</span>
                                <span>{{getVerbandLabel(element.Verband)}}</span>

                                <span>Omschrijving:</span>
                                <span>{{element.SteenElementAccent.KlantNotitie}}</span>


                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="element.IsDakpan">

                            <span>Dakpan:</span>
                            <span [innerHTML]="getRooftileLabel(element.DakElement.Code)"></span>

                            <span>Omschrijving:</span>
                            <span [innerHTML]="getRooftileDescription(element.DakElement.Code)"></span>

                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

        <a class="edit-button button rounded red small-ish" (click)="goBack()">
            Samenstelling aanpassen
        </a>

    </div>




    <div slot="sidebar" class="sidebar-content">

        <div class="scroll-parent-flex desktop-only">

            <div class="scroll-container desktop-only sidebar-container">
                <h3>Persoonsgegevens</h3>
                <div class="client-details">
                    {{order.Client.Bedrijf}} <br />
                    {{getGenderString(order.Client.ContactpersoonGender)}}
                    <!-- {{order.Client.ContactpersoonVoornaam}} -->
                    {{order.Client.ContactpersoonAchternaam}} <br />
                    {{order.Client.Straat}} {{order.Client.Huisnummer}} <br />
                    {{order.Client.Postcode}} {{order.Client.Plaats}} <br />
                    <br />
                    {{order.Client.EmailAdres}} <br />
                    {{order.Client.Telefoonnummer}}
                </div>


                <h3>Projectgegevens</h3>
                <div class="project-details">

                    <span>Projectnummer:</span>
                    <span>{{order.ProjectNumber.toString()| padleft:4}}</span>

                    <span>Plaats:</span>
                    <span>{{order.Project.Plaats}}</span>

                    <span>Projectnaam:</span>
                    <span>{{order.Project.Projectnaam}}</span>

                    <span>Opdrachtgever:</span>
                    <span>{{order.Project.Opdrachtgever}}</span>

                    <span>Architect:</span>
                    <span>{{order.Project.Architect}}</span>

                    <span>Aannemer:</span>
                    <span>{{order.Project.Aannemer}}</span>

                </div>

                <span class="disclaimer">
                    *Wij verzoeken u vriendelijk om te controleren of de bovenstaande
                    gegevens correct zijn. Mochten er gegevens gewijzigd moeten
                    worden, verzoeken wij u om contact met ons op te nemen via
                    configurator@brickpoint.nl
                </span>

            </div>
        </div>
        <a class="edit-button button rounded red small-ish" [ngClass]="{'disabled' : busy}" (click)="clickedSend()">
            Wijziging opslaan
        </a>


    </div>

</app-sidebar>